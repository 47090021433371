@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  width: 95vw;
}

.mainpage {
  height: 90vh;
  overflow: auto;
}

iframe body {
  visibility: hidden;
}

.wp-image-30973 {
  width: 60% !important;
  height: auto !important;
}

.answer img {
  width: 70%;
}

.modal {
  padding: 2rem 0;
}

.introjs-skipbutton {
  display: none;
}

.introjs-button {
  background-image: none !important;
  font-weight: bold !important;
  color: white !important;
  text-shadow: none !important;
  padding: .5em 1.7em !important;
  font-size: 15px !important;
}

.introjs-nextbutton {
  background-color: rgba(250, 76, 6, 1) !important;
}

.introjs-prevbutton {
  background-color: rgba(250, 76, 6, 0.4) !important;
}

.swal-button {
  background-color: rgba(250, 76, 6, 1) !important;
}

.introjs-helperLayer {
  background-color: rgba(255,255,255,.5) !important;
}

.introjs-tooltip {
  font-size: 20px !important;
  text-align: center !important;
}

.video-react .video-react-big-play-button {
  background-color: rgba(62,120,189,0.75);
  color: #FF5200;
  border: none;
}

.swal-text {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}

.introjs-tooltip {
  font-family: 'Poppins', sans-serif;
}

.MuiStepConnector-lineVertical {
  display: none !important;
}

.swal-icon img {
  max-width: 40% !important;
  max-height: 40% !important;
  margin-bottom: 3vh;
}

.row-between {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}

.row-evenly {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  align-items: center;
}

.row-center {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.row-up {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: flex-start;
}

.row-left {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
}

.row-left-up {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-center {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.column-up {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: center;
}

.column-left {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
}

.column-left-evenly {
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  align-items: flex-start;
}

.column-center-evenly {
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  align-items: center;
}

.column-up-down {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: center;
}

.MuiButton-containedPrimary {
  background-color: rgba(250, 76, 6, 1) !important;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: rgba(250, 76, 6, 1) !important;
}

.MuiTableCell-root {
  border: none !important;
}

/* width */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #e0e0e0; 
}
 
/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(250, 76, 6); 
  border-radius: 50px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgba(250, 76, 6, 0.8); 
}

.hidden {
  display: none;
}

#menu {
  transition-duration: 1s !important;
}

.img-see-library .swal-icon img {
  width: 25% !important;
}

.img-see-library .swal-text {
  text-align: center !important;
}

@media (max-width: 1024px) {
  .page {width: 100vw;}

  .mainpage {
    height: 93vh;
  }
}

@media (max-width: 768px) {
  label + .MuiInput-formControl {
    margin-top: 30px !important;
  }

  *::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 540px) {
  .swal-text {
    font-size: 12px;
  }

  .swal-modal {
    width: 300px;
  }

  .mainpage {
    height: 94vh;
  }

  .MuiBadge-badge {
    font-size: 10px !important;
    height: 13px !important;
    min-width: 13px !important;
  }

  label + .MuiInput-formControl {
    margin-top: 25px !important;
  }
}

@media screen and (min-width: 450px) {
  .introjs-tooltip {
    min-width: 400px !important;
  }
}

@media (max-width: 400px) {
  label + .MuiInput-formControl {
    margin-top: 20px !important;
  }
}
